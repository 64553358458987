var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "flex-column",
    },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("base-search", {
            staticStyle: { display: "inline-block" },
            attrs: {
              searchList: _vm.$cbglCbxxSearch,
              listQuery: _vm.listQuery,
              dynamic: true,
            },
            on: { search: _vm.handleFilter, "btn-event": _vm.onBtnClicked },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "bg-white",
          staticStyle: { height: "100%" },
          attrs: { id: "table_box_height" },
        },
        [
          _c("basic-table", {
            attrs: {
              tableHeight: _vm.tableHeight,
              tableHeaderList: _vm.firstHeaderList,
              tableData: _vm.tableData,
              listQuery: _vm.listQuery,
              tableTotal: _vm.tableTotal,
              hasSelection: true,
              isCbxx: true,
              otherColumn: _vm.otherColumn,
            },
            on: {
              pagination: _vm.handleCurrentChange,
              tableRowClick: _vm.tableRowClick,
              handleSelectionChange: _vm.handleSelectionChange,
            },
            scopedSlots: _vm._u([
              {
                key: "operate",
                fn: function (row) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "success" },
                        on: { click: _vm.qiyong },
                      },
                      [_vm._v("启用")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "danger" },
                        on: { click: _vm.jinyong },
                      },
                      [_vm._v("禁用")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "编辑计划", visible: _vm.FormDialog, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.FormDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.addform, "label-width": "80px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "计划名称" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.addform.rwmc,
                      callback: function ($$v) {
                        _vm.$set(_vm.addform, "rwmc", $$v)
                      },
                      expression: "addform.rwmc",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "控制模式" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "控制模式" },
                      model: {
                        value: _vm.addform.kzms,
                        callback: function ($$v) {
                          _vm.$set(_vm.addform, "kzms", $$v)
                        },
                        expression: "addform.kzms",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "闸位", value: "1" } }),
                      _c("el-option", { attrs: { label: "流量", value: "2" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "控制参数" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.addform.kzcs,
                      callback: function ($$v) {
                        _vm.$set(_vm.addform, "kzcs", $$v)
                      },
                      expression: "addform.kzcs",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "执行时间" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: { type: "datetime", placeholder: "选择日期" },
                        model: {
                          value: _vm.addform.starTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.addform, "starTime", $$v)
                          },
                          expression: "addform.starTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "line",
                      staticStyle: { "text-align": "center" },
                      attrs: { span: 2 },
                    },
                    [_vm._v("-")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: { type: "datetime", placeholder: "选择时间" },
                        model: {
                          value: _vm.addform.endTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.addform, "endTime", $$v)
                          },
                          expression: "addform.endTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "立即执行" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "立即执行" },
                      model: {
                        value: _vm.addform.ljzx,
                        callback: function ($$v) {
                          _vm.$set(_vm.addform, "ljzx", $$v)
                        },
                        expression: "addform.ljzx",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "是", value: "1" } }),
                      _c("el-option", { attrs: { label: "否", value: "2" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "开闸密码" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.addform.kzmm,
                      callback: function ($$v) {
                        _vm.$set(_vm.addform, "kzmm", $$v)
                      },
                      expression: "addform.kzmm",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "闸门急停" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "闸门急停" },
                      model: {
                        value: _vm.addform.zmjt,
                        callback: function ($$v) {
                          _vm.$set(_vm.addform, "zmjt", $$v)
                        },
                        expression: "addform.zmjt",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "是", value: "1" } }),
                      _c("el-option", { attrs: { label: "否", value: "2" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.cancelDialog } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmDialog },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }