<template>
  <div class="flex-column" v-loading="loading">
    <div class="filter-container">
      <base-search :searchList="$cbglCbxxSearch" :listQuery='listQuery' v-on:search='handleFilter' v-on:btn-event="onBtnClicked" style="display: inline-block;" :dynamic='true'></base-search>
    </div>
    <div class="bg-white" style="height: 100%;" id="table_box_height">
      <!-- 列表页 -->
      <basic-table :tableHeight="tableHeight" :tableHeaderList="firstHeaderList" :tableData="tableData" :listQuery="listQuery" @pagination="handleCurrentChange" @tableRowClick="tableRowClick" :tableTotal="tableTotal" :hasSelection="true" @handleSelectionChange="handleSelectionChange" :isCbxx="true" :otherColumn="otherColumn">
        <template v-slot:operate="row">
          <!-- <el-image ref="myImg" style="width: 24px; height: 24px;cursor: pointer;" :src="require('@/assets/img/thumbnail.png')" @click.stop="viewImg($event,row.rowData)"> </el-image> -->
          <el-button size="mini" type="success" @click="qiyong">启用</el-button>
          <el-button size="mini" type="danger" @click="jinyong">禁用</el-button>
        </template>
      </basic-table >
    </div>

    <!-- 添加弹窗 -->
    <el-dialog title="编辑计划" :visible.sync="FormDialog" width="30%" >
      <el-form ref="form" :model="addform" label-width="80px">
        <el-form-item label="计划名称">
          <el-input v-model="addform.rwmc"></el-input>
        </el-form-item>
        <el-form-item label="控制模式">
          <el-select v-model="addform.kzms" placeholder="控制模式" style="width: 100%;">
            <el-option label="闸位" value="1"></el-option>
            <el-option label="流量" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="控制参数">
          <el-input v-model="addform.kzcs"></el-input>
        </el-form-item>
        <el-form-item label="执行时间">
          <el-col :span="11">
            <el-date-picker type="datetime" placeholder="选择日期" v-model="addform.starTime" style="width: 100%;"></el-date-picker>
          </el-col>
          <el-col class="line" :span="2" style="text-align: center;">-</el-col>
          <el-col :span="11">
            <el-date-picker type="datetime" placeholder="选择时间" v-model="addform.endTime" style="width: 100%;"></el-date-picker>
          </el-col>
        </el-form-item>
        <el-form-item label="立即执行">
          <el-select v-model="addform.ljzx" placeholder="立即执行" style="width: 100%;">
            <el-option label="是" value="1"></el-option>
            <el-option label="否" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开闸密码">
          <el-input v-model="addform.kzmm"></el-input>
        </el-form-item>
        <el-form-item label="闸门急停">
          <el-select v-model="addform.zmjt" placeholder="闸门急停" style="width: 100%;">
            <el-option label="是" value="1"></el-option>
            <el-option label="否" value="2"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelDialog">取 消</el-button>
        <el-button type="primary" @click="confirmDialog">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口
  
  export default {
    name: 'jhlb',//计划列表
    components: {
      
    },
    props: {
      //设备类型
      // deviceType:[String, Object, Array],
      visible:{
        default:false
      },
      //是否是详情，详情进入不可修改
      isCheckDetail:{
        default:false
      },
    },
    directives: {
      
    },
    data() {
      return {
        loading:false,//遮罩
        listQuery: { // 查询条件
          page: 1,
          limit: 50,
          TreeIds:[],//选中集合
          queryType: '0',
          queryKey:'UserNumber',
          queryValue:'',
          OtherKey:'',//查询条件
          OtherValue:'',//查询内容
          issueType:'0',//操作范围
          ids:[],//操作范围传值
          timer:'',
          beginTime:'',
          endTime:'',
          repeat:'',
        },
        // firstHeaderList:[],// 主列表列定义
        firstHeaderList:[// 主列表列定义
          {
            key :  "jhmc",
            comment :  "计划名称",
            description :  "计划名称",
          },
          {
            key :  "jhnr",
            comment :  "计划内容",
            description :  "计划内容",
          },
          {
            key :  "zxcs",
            comment :  "执行次数",
            description :  "执行次数",
          },
          {
            key :  "zxsj",
            comment :  "执行时间",
            description :  "执行时间",
          },
          {
            key :  "bz",
            comment :  "备注",
            description :  "备注",
          },
          {
            key :  "zt",
            comment :  "状态",
            description :  "状态",
          },
        ],
        tableTotal:0,//明细条数
        chooseList:[],//多选数据
        tableData:[
          {jhmc:'测试计划',jhnr:'测试一小时后执行',zxcs:1,zt:'正在运行',zxsj:'2023-12-25 10:12:38',bz:'',}
        ],
        tableHeight:500,//表格高度
        otherColumn:[//
          {
            label:'操作',
            slotName:'operate',
          }
        ],
        FormDialog:false,//
        FormList:{//表格列
          column:[
            {columnKey:'rwmc',columnDescription:'任务名称',columnType:'text',prop:'CurrentReading',width:'120',placeholder:"任务名称",step:0.0001,},
            {columnKey:'kzms',columnDescription:'控制模式',columnType:'select',prop:'UserNumber',width:'120',placeholder:"控制模式",
              seleceOption:[
                {name:"闸位",dtCode:1},
                {name:"流量",dtCode:2},
              ]
            },
            {columnKey:'kzcs',columnDescription:'控制参数',columnType:'text',prop:'SurfaceNum',width:'120',placeholder:"控制参数",},
            {columnKey:'zxsj',columnDescription:'执行时间',columnType:'text',prop:'SurfaceAddress',width:'120',placeholder:"执行时间",},
            {columnKey:'ljzx',columnDescription:'立即执行',columnType:'select',prop:'SurfaceAddress',width:'120',placeholder:"立即执行",
              seleceOption:[
                {name:"是",dtCode:1},
                {name:"否",dtCode:2},
              ]
            },
            {columnKey:'kfmm',columnDescription:'开闸密码',columnType:'text',prop:'SurfaceAddress',width:'120',placeholder:"开闸密码",},
            {columnKey:'zmjt',columnDescription:'闸门急停',columnType:'text',prop:'SurfaceAddress',width:'120',placeholder:"闸门急停",},
          ],
          //校验
          rule:{
            CurrentReading: [{ required: true, message: "当前读数不能为空", trigger: "blur" }, ],
          }
        },
        addform:{
          rwmc:'',//计划名称
          kzms:'',//控制模式
          kzcs:'',//控制参数
          starTime:'',//开始时间
          endTime:'',//结束时间
          ljzx:"",//立即执行
          kzmm:"",//开闸密码
          fmjt:"",//闸门急停
        },
      }
    },
    watch: {
      
    },
    computed: {
      
    },
    filters: {
      
    },
    created() {
      // this.getList()
    },
    mounted() {
      // 计算table高度
      this.$nextTick(()=>{
        let ele=document.getElementById("table_box_height");
        this.tableHeight=ele.offsetHeight + 80
        window.addEventListener('resize', () => { 
          let ele=document.getElementById("table_box_height");
          this.tableHeight=ele.offsetHeight + 80
          this.$forceUpdate()
        });
        // 计算table高度
      })
    },
    methods: {
      qiyong(){
        this.tableData[0].zt = '正在运行'
      },
      jinyong(){
        this.tableData[0].zt = '停止'
      },
      // 获取数据
      getList() {
        this.loading = true
        basicDataApi.cbxxGetList(this.listQuery).then(response => {
          if(response.code == 200){
            response.columnHeaders.forEach((item) => { // 首字母小写
              item.key = item.key.substring(0, 1).toLowerCase() + item.key.substring(1)
            })
            this.firstHeaderList = response.columnHeaders.filter(u => u.browsable && u.isList)
            this.tableData = response.data
            this.tableTotal = response.count
          }else{
            this.firstHeaderList = []
            this.tableData = []
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
          this.loading = false
        }).catch(()=>{
          this.firstHeaderList = []
          this.tableData = []
          this.loading = false
        })
      },
      // 搜索
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
      // 切换分页
      handleCurrentChange(val) {
        this.tableChooseRow = {}//重置选中项
        this.listQuery.page = val.page
        this.listQuery.limit = val.limit
        this.getList()
      },
      // 行点击
      tableRowClick(row){
        this.tableChooseRow = row
      },
      //多选
      handleSelectionChange(val){
        this.chooseList = val
      },
      handleDelete(row) { // 多行删除
        this.tableData.splice(this.tableData.findIndex(e=>e.serialNumber === row.serialNumber),1)
      },
      // 按钮点击
      onBtnClicked: function(domId) {
        console.log('you click:' + domId)
        if(domId == 'btnAdd'){//新增
          this.FormDialog = true
          return
        }
        if(domId == 'btnEdit'){//编辑
          if(this.chooseList.length != 0){
            this.$message.error('请选择一个设备进行操作！');
            return;
          }
          this.FormDialog = true
          return
        }
        if(domId == 'btnDel'){//删除
          if(this.chooseList.length <= 0){
            this.$message.error('请选择需要删除的设备！');
            return;
          }
          this.$message.success('删除成功');
          return
        }
        
        if(domId == 'btnExport'){//导出
          this.handleDownExcel()
          return
        }
      },
      // add弹窗提交
      confirmDialog(){
        console.log(this.addform)
        this.FormDialog = false
        return
        if(!this.addform.UserNumber && !this.addform.SurfaceNum && !this.addform.SurfaceAddress){
          this.$notify({
            message: '至少输入用户编号、表编号、表地址中的一个',
            type: 'warning',
            duration: 2000
          });
          return
        }
        basicDataApi.cbxxRgcb(this.addform).then(response => {
          if(response.code == 200){
            this.$notify({
              message: response.message,
              type: 'success',
              duration: 2000
            });
            this.addform={
              UserNumber:'',//用户编号
              SurfaceNum:'',//表编号
              SurfaceAddress:'',//表地址
              CurrentReading:'',//当前读数
            }
            this.getList()
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
          this.FormDialog = false
        })
      },
      // add弹窗取消
      cancelDialog(val){
        this.FormDialog = val
      },
    },
  }

</script>

<style lang="scss" scoped>

</style>
